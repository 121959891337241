<template>
  <Html>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </Html>
</template>

<script lang="ts" setup>
import useCommonStore from '~/store/common';

const router = useRouter();
const commonStore = useCommonStore();

const handleKeydown = (e: KeyboardEvent): void => {
  if (e.code === 'Escape') {
    router.push('/');
  }
};

onMounted(() => {
  const lastHeight = window.innerHeight;
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  window.addEventListener('resize', () => {
    if (
      window.innerWidth > window.innerHeight ||
      lastHeight === window.innerHeight
    ) {
      setVh();
    }
  });

  setVh();

  document.addEventListener('keydown', handleKeydown);

  // fetch init city data
  commonStore.getCityData();

  // fetch init headliners
  if (!commonStore.headliners?.length) {
    commonStore.getHeadliners();
  }

  if (typeof window.ym === 'function') {
    window.ym(92511690, 'getClientID', function(clientID: string) {
      localStorage.setItem('ym_client_id', clientID);
    });
  } 
});
</script>

<style lang="postcss">

</style>
